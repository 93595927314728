"use client"
import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover} from '@/styles/globalStyleVars';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import Button from "@/components/Button";
import reactHtmlParser from "react-html-parser";
import VideoBanner from "@/components/VideoBanner";
import ScrollArrow from "@/components/ScrollArrow";


const MediaContent = ({ element, index }) => {
    // Start with null to prevent incorrect initial render
    const [isWideScreen, setIsWideScreen] = useState(null);
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);

        const checkScreenSize = () => {
            setIsWideScreen(window.innerWidth > 767);
        };

        // Set initial screen size
        checkScreenSize();

        // Update screen size on resize
        window.addEventListener('resize', checkScreenSize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    // Return null or a loading state on server-side rendering
    // or before the component has mounted
    if (!hasMounted || isWideScreen === null) {
        return (
            <Img
                src={element?.images?.find((f) => f?.banner === "on")?.full_path}
                alt={'home banner'}
                loading={index === 0 ? 'eager' : 'lazy'}
            />
        );
    }

    // Helper function to find image/video paths
    const findMediaPath = (condition) =>
        element?.images?.find(f => f[condition] === "on")?.full_path;

    if (isWideScreen) {
        const videoPath = findMediaPath('is_video');
        const fallbackPath = findMediaPath('background');
        const imagePath = findMediaPath('banner');

        return videoPath ? (
            <div>
                <VideoBanner
                    fallbackImage={fallbackPath}
                    src={videoPath}
                />
            </div>
        ) : (
            <Img
                src={imagePath}
                alt={'home banner'}
                loading={index === 0 ? 'eager' : 'lazy'}
            />
        );
    } else {
        const mobileVideoPath = findMediaPath('mobile_video');
        const fallbackPath = findMediaPath('background');
        const thumbPath = findMediaPath('thumb');
        const bannerPath = findMediaPath('banner');

        return mobileVideoPath ? (
            <div>
                <VideoBanner
                    src={mobileVideoPath}
                    fallbackImage={fallbackPath}
                />
            </div>
        ) : (
            <Img
                src={thumbPath || bannerPath}
                alt={'home banner'}
                loading={index === 0 ? 'eager' : 'lazy'}
            />
        );
    }
};

const Banner = ({data}) => {

    console.log(data);
    const [offset, setOffset] = useState();

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)
        })
    }, []);

    const interleaveOffset = 0.5;

    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slideProgress = swiper.slides[i].progress;
            const innerOffset = swiper.width * interleaveOffset;
            const innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".slide-inner").style.transform = `translate3d(${innerTranslate}px, 0, 0)`;
        }
    };

    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i].querySelector(".slide-inner").style.transition = `${speed}ms`;
        }
    };

    const [isWideScreen, setIsWideScreen] = useState(768);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsWideScreen(window.innerWidth > 767);
        };

        // Set initial screen size
        checkScreenSize();

        // Update screen size on resize
        window.addEventListener('resize', checkScreenSize);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    return (
        <StyledBanner className={'home-banner'} offset={offset}>
            <ScrollArrow offset={offset}/>
            {
                data?.posts?.list &&
                <Swiper
                    modules={[Autoplay, Pagination, Navigation]}
                    spaceBetween={0}
                    autoplay={{ delay: 10000 }}
                    speed='1500'
                    loop={true}
                    onProgress={handleProgress}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    watchSlidesProgress={true}
                    mousewheelControl={true}
                    keyboardControl={true}
                    pagination={{ clickable: true }}
                    className="mySwiper"
                >
                    {
                        data?.posts?.list && data?.posts?.list.length>0
                        && data?.posts?.list.map((element,index)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <div className="slide-inner">
                                        <MediaContent
                                            element={element}
                                            index={index}
                                        />
                                        {/*{*/}
                                        {/*    isWideScreen ? (*/}
                                        {/*        element?.images?.find((f) => f?.is_video === "on") ? (*/}
                                        {/*            <div>*/}
                                        {/*                <VideoBanner*/}
                                        {/*                    fallbackImage={element?.images?.find((f) => f?.background === "on")?.full_path}*/}
                                        {/*                    src={element?.images?.find((f) => f?.is_video === "on")?.full_path}/>*/}
                                        {/*            </div>*/}
                                        {/*        ) : (*/}
                                        {/*            <Img*/}
                                        {/*                src={element?.images?.find((f) => f?.banner === "on")?.full_path}*/}
                                        {/*                alt={'home banner'}*/}
                                        {/*                loading={index === 0 ? 'eager' : 'lazy'}*/}
                                        {/*            />*/}
                                        {/*        )*/}
                                        {/*    ) : (*/}
                                        {/*        element?.images?.find((f) => f?.mobile_video === "on") ? (*/}
                                        {/*            <div>*/}
                                        {/*                <VideoBanner src={element?.images?.find((f) => f?.mobile_video === "on")?.full_path} fallbackImage={element?.images?.find((f) => f?.background === "on")?.full_path} />*/}
                                        {/*            </div>*/}
                                        {/*        ) : (*/}
                                        {/*            <Img*/}
                                        {/*                src={*/}
                                        {/*                    element?.images?.find((f) => f?.thumb === "on")?.full_path*/}
                                        {/*                        ? element?.images?.find((f) => f?.thumb === "on")?.full_path*/}
                                        {/*                        : element?.images?.find((f) => f?.banner === "on")?.full_path*/}
                                        {/*                }*/}
                                        {/*                alt={'home banner'}*/}
                                        {/*                loading={index === 0 ? 'eager' : 'lazy'}*/}
                                        {/*            />*/}
                                        {/*        )*/}
                                        {/*    )*/}
                                        {/*}*/}
                                        <Container className={'container_info'}>
                                            <Row>
                                                <Col md={12}>
                                                    <div className="slide-inner__info">
                                                        {
                                                            index === 0 ?
                                                                <h1>{reactHtmlParser(element?.data?.subtitle)}</h1> :
                                                        <h2>{reactHtmlParser(element?.data?.subtitle)}</h2>
                                                        }
                                                        <p>{reactHtmlParser(element?.data?.short_desc)}</p>
                                                        <Button
                                                            text={'Start your Journey'}
                                                            src={'/'}
                                                            color={'#F6F8FE'}
                                                            arrowfilter={`brightness(100)`}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </SwiperSlide>
                    )
                  })
                 }

                </Swiper>
         }

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  position: relative;
  overflow: hidden;
  .home-banner {
    .container {
      position: relative;
    }
  }

  .mobile-version {
    display: none;
  }

  .sweeper-nav {
    position: absolute;
    left: ${p => p.offset + 15}px;
    right: ${p => p.offset}px;
    bottom: 40px;
    z-index: 999;

    ul {
      display: flex;
    }

    @media (max-width: 767px) {
      bottom: 5%;
    }
    @media (max-width: 376px) {
      bottom: 3%;
    }
    
  }

  .banner-slider__init {
    display: flex;
    justify-content: space-between;

    &__nav {
      display: flex;
      justify-content: flex-end;
      position: relative;
      align-items: flex-end;
      z-index: 5;

      &.mobile-version {
        display: none;
      }

      .slider-next {
        margin-left: 15px;
      }
    }

    &__slider {
      width: 28%;
      margin-right: 15px;

      .banner-slider__single {
        display: block;

        &__inner {
          padding-top: calc(240 / 370 * 100%);
          position: relative;
          border: 1px solid rgba(255, 255, 255, 0.5);
          background: rgba(0, 0, 0, 0.55);
          backdrop-filter: blur(3px);
          transition: .5s ease;

          a {
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 2;
            left: 0;
            top: 0;
          }

          img {
            position: absolute;
            top: 30px;
            left: 30px;
          }
          
          @media(max-width: 767px){
            img{
              top: 50px;
            }
          }


          &__bottom {
            position: absolute;
            left: 30px;
            right: 30px;
            bottom: 20px;
            color: #FFF;
            display: flex;
            justify-content: space-between;

            p {
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
            }

            h5 {
              font-size: 18px;
              font-weight: 600;
              line-height: 27px;
            }
          }

          h6 {
            position: absolute;
            top: 85px;
            right: 30px;
            left: 30px;
            color: #FFF;

            span {
              display: block;
              text-align: right;
              font-size: 14px;
              font-weight: bold;
            }

            @media (max-width: 767px) {
              right: 0px;
              top: 15px;
              font-size: 13px;
              span {
                font-size: 12px;
              }
            }
          }
        }
      }
    }


    @media(max-width: 991px){
      &__slider {
        width: 65%;
        margin-right: 30px;
      }
      &__nav {
        margin-left: 30px;
      }
    }
    @media (max-width: 767px) {
      display: block;
      &__slider {
        width: 65%;
      }
      &__nav {
        justify-content: flex-start;
        margin-left: 0px;
      }
    }
    @media(max-width: 415px){
      &__slider {
        width: 75%;
      }
    }
  }

  .home-banner {
    .container {
      position: relative;
    }
  }
    

  .swiper-button-prev, .swiper-button-next {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;

  }

  .slide-inner {
    position: relative;
    height: 100vh;

    .container_info {
        position: absolute;
        left: 50%;
        bottom: 40px;
        transform: translateX(-50%);
        z-index: 999999999999;

        h2,h1{
            color: #F6F8FE;
            margin-bottom: 20px;
            text-align: center;
            @media(min-width: 1580px){
                width: 70%;
            }
        }
        p{
            color: #F6F8FE;
            text-align: center;
        }
      .dc-btn {
        z-index: 999;
      }

      @media (max-width: 767px) {
        top: 40% !important;
          left: 0;
      }

      @media (max-width: 400px) {
        top: 40% !important;
      }
      
    }

    .global-image {
        img{
            position: absolute;
            height: 100%;
            width: 100%;
            inset: 0px;
            object-fit: cover;
            color: transparent;
        }
        &:after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.8));
        }
    }

    &__info {
      position: relative;
      overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
      h1,h2 {
        color: #FFF;
        transform: translateY(400px);
        transition: 1s all cubic-bezier(0.4, 0, 0, 1);
        font-size: 80px;
          line-height: 80px;
          
          span{
              font-style: italic;
          }
       
      }
        
        p{
            transform: translateY(400px);
            transition: 1s all cubic-bezier(0.4, 0, 0, 1);  
            font-size: 18px;
        }

      h4 {
        margin-bottom: 12px;
        color: #FFF;
        
        
        @media(max-width: 767px){
          font-size: 16px;
          line-height: 20px;
        }

      }

      .dc-btn {
        margin-top: 30px;
        position: relative;
        transform: translateY(400px);
        transition-delay: 0.5s;
        transition: 1s all cubic-bezier(0.4, 0, 0, 1);
      }
      @media(max-width: 376px){
        h1{
          font-size: 26px;
        }
        h4{
          font-size: 10px;
        }
      }
    }
  }

  .swiper-slide-active {
    .slide-inner {
      &__info {
        h1,h2 {
          transform: translateY(0px);
        }

        p {
          transform: translateY(0px);
        }

        .dc-btn {
          transform: translateY(0px);
        }
      }

    }
  }

  .swiper {
    position: relative;
  }

  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2,h1 {
          font-size: 84px;
          line-height: 84px;
        }
        .dc-btn {
          
        }
      }
    }
  }
  @media (max-width: 767px) {
    .banner-slider__init__nav {
      display: none;

      &.mobile-version {
        margin-top: 40px;
        display: flex;
      }
    }


    .slide-inner {
      .container_info {
        transform: unset;
        bottom: 140px;
          top: unset !important;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2,h1 {
            font-size: 42px;
            line-height: 42px;
        }

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

  }
  @media(max-width: 376px){
    .banner-slider__init__nav {
      display: none;

      &.mobile-version {
        margin-top: 15px;
        display: flex;
      }
    }
    .banner-slider__init__slider .banner-slider__single__inner img{
      width: 15%;
      top: 50px;
    }

  }

    .swiper-pagination{
        text-align: unset;
        right: ${p => p.offset + 15 +'px'};
        bottom: 50px;
        left: unset;
        width: max-content;
        @media (max-width: 991px) and (min-width: 768px){
            right: 30px;
            bottom: 30px;
        }
        @media(max-width: 767px){
            right: 15px;
        }
        
    }
    .swiper-pagination-bullet{
        background-color: #FFF;
        opacity: 1;
        width: 8px;
        height: 8px;
        transition: all 0.3s ease-in-out;
        margin-right: 15px !important;
        border-radius: 50% !important;
    }
    .swiper-pagination-bullet-active{
        position: relative;
        &:after{
            position: absolute;
            content: '';
            width: 24px;
            height: 24px;
            background: transparent;
            border: 1px solid #FFF;
            top: -8px;
            left: -8px;
            border-radius: 50% !important;
        }
    }
`;
export default Banner;

