'use client'
import {Img} from "@/components/Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import Title from "@/components/Title";
import Button from "@/components/Button";
import {title} from "@/styles/globalStyleVars";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {useGSAP} from "@gsap/react";
import {usePathname} from "next/navigation";
import gsap from "gsap";

gsap.registerPlugin(ScrollTrigger);
const HomeTown = ({data}) => {
    const pathname = usePathname();
    const listRef = useRef(null);

    useGSAP(() => {
        let scrollTrigger;

        if (listRef.current && window.innerWidth > 767) {
            setTimeout(() => {
                scrollTrigger = ScrollTrigger.create({
                    trigger: listRef.current,
                    start: "top-=50px top",
                    endTrigger: ".home-second-town",
                    end: "top-=50px top",
                    pin: true,
                    pinSpacing: false,
                    scrub: true,
                    markers: false,
                });
                ScrollTrigger.refresh();
            }, 100); // adjust the delay as necessary
        }

        // Clean up on unmount
        return () => {
            if (scrollTrigger) scrollTrigger.kill();
        };
    }, [ pathname,data]);


    return (
        <StyledTown className={`home-town mb-100`} ref={listRef}>
            <Container>
                <div className="bg-img">
                    <Img src={data?.images?.list?.find((f) => f?.background === "on")?.full_path}/>
                </div>
                <Row>
                    <Col md={{span: 10, offset: 1}}>
                        <div className="home-town__top">
                            {
                                data?.section_data?.subtitle &&
                                <h3 className={'split-left'}>{data?.section_data?.subtitle}</h3>
                            }

                            <ul>
                                {
                                    data?.posts?.list && data?.posts?.list?.length >0 &&
                                    data?.posts?.list?.map((element,index)=>{
                                        return (
                                            <li>
                                                <img src={element?.images?.[0]?.full_path} alt={element?.data?.title} width={'16px'} height={'16px'} />
                                                <p>{element?.data?.subtitle}</p>
                                            </li>
                                        )
                                    })
                                }

                            </ul>
                            <Button
                                text={'Explore Project'}
                                src={'/asian-shanti-nibash'}
                                color={'#F6F8FE'}
                                arrowfilter={`brightness(100)`}
                                margin={'65px 0 60px 0'}
                            />
                        </div>
                        <div className="home-town__middle">
                            <div className="logo">
                                <img src={data?.images?.list?.find((f) => f?.icon === "on")?.full_path} alt="logo"/>
                            </div>
                            <div className="video">
                                <video
                                    loop
                                    playsInline
                                    autoPlay
                                    muted
                                >
                                    {/* <source src="/media/cc0-videos/flower.webm" type="video/webm" /> */}
                                    <source src={data?.images?.list?.find((f) => f?.is_video === "on")?.full_path} type="video/mp4"/>
                                </video>

                            </div>
                        </div>
                        <div className="home-town__bottom">
                            <Button
                                text={'Explore Project'}
                                src={'/asian-shanti-nibash'}
                                color={'#F6F8FE'}
                                arrowfilter={`brightness(100)`}
                                margin={'65px 0 60px 0'}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledTown>
    );
};

const StyledTown = styled.section`
    will-change: transform;
    &.home-town{
        background-color: #282C32;
        .container{
            position: relative;
            .bg-img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                border: 1px solid rgba(160, 255, 203, 0.50);
                img{
                    border-radius: 10px;
                }
                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%,rgba(0, 0, 0, 0.20) 50%, rgba(0, 0, 0, 0.90) 100%);
                }
                @media(max-width: 767px){
                    height: 85%;
                }
            }
            @media(max-width: 767px){
                width: 90%;
            }
        }
        .home-town__top{
            margin-top: 70px;
            h3{
                color: #FFF;
                margin-bottom: 30px;
                font-family: ${title};
                font-weight: 400;
            }
            ul{
                display: flex;
                gap: 20px;
                margin-bottom: 30px;
                li{
                    padding: 11px 20px 13px 20px;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px;
                    border-radius: 30px;
                    border: 1px solid rgba(160, 255, 203, 0.50);
                    background: rgba(23, 40, 42, 0.55);
                    backdrop-filter: blur(2px);
                    color: #FFF;
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    p{
                        font-size: 12px;
                        line-height: 16px;
                    }
                    
                }
                @media(max-width: 767px){
                    flex-wrap: wrap;
                }
            }
            .dc-btn{
                display: none;
                @media(max-width: 767px){
                    display: block;
                }
            }
        }
        .home-town__middle{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            .logo{
                width: 80px;
                height: 80px;
                border-radius: 50%;
                background-color: #FFF;
                position: absolute;
                right: 260px;
                @media(min-width: 1560px){
                    right: 360px;
                }
                @media(max-width: 767px){
                    top: -35px;
                    left: 50%;
                    transform: translate(-50%);
                }
            }
            @media(max-width: 767px){
                margin-top: 100px;
            } 
        }
        
        .home-town__bottom{
            .dc-btn{
                @media(max-width: 767px){
                    display: none;
                }
            }
        }
        .video{
            height: auto;
            border-radius: 10px;
            width: 300px;
            video{
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
            @media(min-width: 1560px){
                width: 400px;
            }
            @media(max-width: 767px){
                min-width: 100%;
            }
        }
    }

`;


export default HomeTown;
