"use client";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useEffect, useState } from "react";
import Title from "@/components/Title";
import reactHtmlParser from "react-html-parser";
import { useGSAP } from "@gsap/react";
import { CSSPlugin, gsap, TimelineLite } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {Black,title} from "@/styles/globalStyleVars";
import {Autoplay, Navigation, Pagination} from "swiper";
import Button from "@/components/Button";
import {usePathname} from "next/navigation";

const Component = ({ data }) => {
    const [offset, setOffset] = useState();
    const pathname = usePathname()

    useEffect(() => {

        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])

    const [windowSize, setWindowSize] = useState();

    useEffect(() => {
        if (typeof window !== "undefined") {
            const offset = () => setWindowSize(window.innerWidth + 15);
            window.addEventListener("resize", () => {
                offset();
            });
            offset();
        }
    }, []);

    // pin & scroll animation
    useGSAP(() => {
        if (window?.innerWidth > 767) {
            const container = document.querySelector(".container");
            const leftSide = document.querySelector(".scroll-video__left");
            const leftWidth = leftSide.offsetWidth;
            const leftOffset = offset;
            const items = document.querySelectorAll(".scroll-video-items .single-item");
            const itemsWidth = items[0].offsetWidth; // Width of a single item
            // const totalItemsWidth = itemsWidth * (items.length -1); // Total width of all items
            const totalItemsWidth = itemsWidth * items.length; // Total width of all items
            const containerWidth = container.offsetWidth; // Container width

            // Calculate the scrollable width: Total width of items minus the container's width
            const scrollableWidth = totalItemsWidth - containerWidth + leftWidth + leftOffset ;

            // Set initial position based on container offset
            gsap.set(".scroll-video-items", {
                x: 0,
            });

            gsap.timeline({
                scrollTrigger: {
                    id: `w`,
                    trigger: ".scroll-video",
                    start: `center center`,
                    end: `+=${scrollableWidth}`,
                    // end: `+=${totalItemsWidth}`, // End the trigger after the total width of all items has been scrolled
                    pin: true,
                    scrub: 1,
                    toggleActions: "play none none reverse",
                },
            }).to(".scroll-video-items", {
                ease: "linear",
                x: -scrollableWidth,
                // x: -totalItemsWidth + container.offsetLeft, // Move the items based on their total width
                duration: 1,
            });
        }

        // return () => {
        //     ScrollTrigger.killAll("w");
        // };
        return () => {
            ScrollTrigger.getById("w")?.kill();
        };
    }, [pathname,offset]);

    return (
        <StyleComponent className="scroll-video" id={"craft"} offset={offset}>
            {/* video scroll for desktop  */}
            <Container fluid>
                <Row>
                    <Col md={6} className={'scroll-video__left'}>
                        <Title text={data?.section_data?.short_desc} color={'#FFF'} animClass={'split-left'} margin={'0 0 40px 0'}/>
                        <p className={'fade-up'}>{reactHtmlParser(data?.section_data?.description)}</p>
                        <Button
                            text={'Invest Now'}
                            src={'/contact'}
                            color={'#F6F8FE'}
                            arrowfilter={`brightness(100)`}
                            margin={'65px 0 60px 0'}
                        />
                    </Col>
                    <Col md={6}>
                            <div className="scroll-video-items">
                                {
                                    data?.posts?.list && data?.posts?.list?.length > 0 &&
                                    data?.posts?.list?.map((element, index) => {
                                        return (
                                            <div className="single-item">
                                                <p className={'number'}>{index + 1}</p>
                                                <img src={element?.images?.[0]?.full_path} alt=""/>
                                                <h4 className={'split-up'}>{reactHtmlParser(element?.data?.subtitle)}</h4>
                                                <p className={'fade-up'}>{reactHtmlParser(element?.data?.description)}</p>
                                            </div>
                                        )
                                    })
                                }

                                <div className="single-item">
                                    <Button
                                        text={'Invest Now'}
                                        src={'/contact'}
                                        color={'#F6F8FE'}
                                        arrowfilter={`brightness(100)`}
                                    />
                                </div>
                                {/*    );*/}
                                {/*})}*/}
                            </div>
                    </Col>
                </Row>
            </Container>


        </StyleComponent>
    );
};
const StyleComponent = styled.section`
    background-color: ${Black};
    position: relative;
    margin-top: -2px;
    padding-bottom: 300px;
    padding-top: 300px;
  .scroll-video {
    width: 100%;
      
  }
    .scroll-video__left{
        padding-right: 80px;
        position: relative;
        z-index: 99999;
        overflow: hidden;
        background: ${Black};
        padding-left: ${props => props.offset + 15}px;
        p{
            color: #FFF;
        }
    }

  .scroll-video-items {
    width: 100%;
    /* transform: translateX(${(p) => p.offset}px); */
    /* transform: translateX(230px); */
    @media (min-width: 767px) {
      display: flex;
      gap: 30px;
    }
    .single-item{
        padding: 100px 50px 120px 70px;
        background-color: #32363C;
        min-width: 400px;
        position: relative;
        border-radius: 10px;
        .number{
            position: absolute;
            top: 50px;
            right: 0;
            font-size: 200px;
            font-style: italic;
            font-family: ${title};
            color: #282C32;
        }
        h4,p{
            color: #FFF;
        }
        h4{
            font-family: ${title};
            margin-top: 130px;
            margin-bottom: 40px;
        }
        &:nth-last-child(1){
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
  }

  @media (max-width: 767px) {
    .single-item{
        min-width: unset !important;
        margin-bottom: 30px;
        padding: 40px 20px !important;
        .number{
            font-size: 120px !important;
            line-height: 120px !important;
            top: 0 !important;
        }
        h4{
            margin-top: 60px !important;
            margin-bottom: 20px !important;
        }
    }
      padding-bottom: 150px;
      padding-top: 80px;
  }
`;

export default React.memo(Component);
