"use client"
import React, {useRef} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import 'swiper/css';
import "swiper/css/pagination";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Img} from "@/components/Img";
import {Pagination, Navigation, EffectCreative, Autoplay, EffectFade} from "swiper";
import ReactHtmlParser from "react-html-parser";
import {text, hover, Transition, title} from "@/styles/globalStyleVars";
import reactHtmlParser from "react-html-parser";
import Title from "@/components/Title";


const MyComponent = ({background, data}) => {

    return (
        <StyledComponent background={background} className={'amenities as-image-text-slider'}>
            <Container>
                <Row>
                    <Col className={'amenities__big'} lg={{span: 5}}>
                        <Title text={data?.section_data?.short_desc} margin={'0 0 40px 0'} animClass={'split-left'}/>
                        <div className="amenities__big__slider">
                            {
                                data?.posts?.list && data?.posts?.list?.length > 0 &&
                            <Swiper
                                effect={"fade"}
                                fadeEffect={{
                                    crossFade: true,
                                }}
                                modules={[Autoplay, Pagination, Navigation, EffectCreative,EffectFade]}
                                loop={true}
                                allowTouchMove={false}
                                autoplay={false}
                                pagination={{
                                    // el: '.swiper-pagination',
                                    type: "fraction",
                                }}
                                navigation={{
                                    prevEl: '.prev',
                                    nextEl: '.next',
                                }}
                            >
                                {
                                    data?.posts?.list && data?.posts?.list?.length > 0 &&
                                    data?.posts?.list?.map((element, index) => {
                                        return (
                                <SwiperSlide key={index}>
                                    <div className="single">
                                        <div className="single__img ">
                                            <Img src={element?.images?.find((f) => f?.background === "on")?.full_path}/>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                )
                              })
                           }
                            </Swiper>
                       }
                        </div>
                        <div className="amenities__small__slider "  data-speed={"1.2"}>
                            <Swiper
                                effect={"fade"}
                                fadeEffect={{
                                    crossFade: true,
                                }}
                                modules={[Autoplay, Pagination, Navigation, EffectCreative,EffectFade]}
                                loop={true}
                                allowTouchMove={false}
                                autoplay={false}
                                pagination={{
                                    // el: '.swiper-pagination',
                                    type: "fraction",
                                }}
                                navigation={{
                                    prevEl: '.prev',
                                    nextEl: '.next',
                                }}
                            >
                                {
                                    data?.posts?.list && data?.posts?.list?.length > 0 &&
                                    data?.posts?.list?.map((element, ind) => {
                                        return (
                                <SwiperSlide key={ind}>
                                    <div className="single">
                                        <img  src={element?.images?.find((f) => f?.thumb === "on")?.full_path} alt="person"/>
                                    </div>
                                </SwiperSlide>

                               )
                              })
                                }
                            </Swiper>
                        </div>
                    </Col>
                    <Col className={'amenities__right'} lg={{span: 7}}>
                        <Title text={data?.section_data?.short_desc} margin={'50px 0 60px 0'} animClass={'split-left'}/>

                        <div className="amenities__right__bottom">
                            <div className="amenities__right__text">
                                <Swiper
                                    Swiper
                                    effect={"fade"}
                                    fadeEffect={{
                                        crossFade: true,
                                    }}
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative,EffectFade]}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        type: 'fraction'
                                    }}
                                    navigation={{
                                        prevEl: '.prev',
                                        nextEl: '.next'
                                    }}
                                    onBeforeInit={(swiper) => {
                                        swiper.params.navigation.prevEl = '.prev';
                                        swiper.params.navigation.nextEl = '.next';
                                    }}
                                    loop={true}
                                    autoplay={false}
                                >
                                    {
                                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                                        data?.posts?.list?.map((element, i) => {
                                            return (
                                    <SwiperSlide key={i} className={'fade-up'}>
                                        <div className="content-wrapper">
                                            <p className={'review'}>
                                                {reactHtmlParser(element?.data?.description)}
                                            </p>
                                            <h5>{reactHtmlParser(element?.data?.subtitle)}</h5>
                                            <p>{reactHtmlParser(element?.data?.short_desc)}</p>
                                        </div>
                                    </SwiperSlide>

                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                            <div className="solutions-nav">
                                <ul>
                                <li className={"prev"}>
                                        <svg id="Button_-_Nav_-_Left" data-name="Button - Nav - Left"
                                             xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                             viewBox="0 0 40 40">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                               stroke-width="1" opacity="0.3">
                                                <rect width="40" height="40" rx="20" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/>
                                            </g>
                                            <g id="Rectangle_493" data-name="Rectangle 493"
                                               transform="translate(40 40) rotate(180)" fill="none" stroke="#fff"
                                               stroke-width="1" stroke-dasharray="2 520" opacity="0">
                                                <rect width="40" height="40" rx="20" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(15 14.5)">
                                                <g id="Group_4823" data-name="Group 4823">
                                                    <line id="Line_9" data-name="Line 9" x1="5" y2="5" fill="none"
                                                          stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x2="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x1="10"
                                                      transform="translate(0 5)"
                                                      fill="none" stroke="#fff" stroke-linecap="round"
                                                      stroke-width="1"/>
                                            </g>
                                        </svg>
                                    </li>

                                    <li className={"next"}>
                                        <svg id="Button_-_Nav_-_Right" data-name="Button - Nav - Right"
                                             xmlns="http://www.w3.org/2000/svg" width="40" height="40"
                                             viewBox="0 0 40 40">
                                            <g id="Rectangle_492" data-name="Rectangle 492" fill="none" stroke="#fff"
                                               stroke-width="1" opacity="0.3">
                                                <rect width="40" height="40" rx="20" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/>
                                            </g>
                                            <g id="Rectangle_493" data-name="Rectangle 493"
                                               transform="translate(40 40) rotate(180)" fill="none" stroke="#fff"
                                               stroke-width="1" stroke-dasharray="2 520" opacity="0">
                                                <rect width="40" height="40" rx="20" stroke="none"/>
                                                <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="none"/>
                                            </g>
                                            <g id="Group_4824" data-name="Group 4824" transform="translate(15 14.5)">
                                                <g id="Group_4823" data-name="Group 4823" transform="translate(5)">
                                                    <line id="Line_9" data-name="Line 9" x2="5" y2="5" fill="none"
                                                          stroke="#fff" stroke-linecap="round" stroke-width="1"/>
                                                    <line id="Line_10" data-name="Line 10" x1="5" y2="5"
                                                          transform="translate(0 5)" fill="none" stroke="#fff"
                                                          stroke-linecap="round" stroke-width="1"/>
                                                </g>
                                                <line id="Line_11" data-name="Line 11" x2="10"
                                                      transform="translate(0 5)"
                                                      fill="none" stroke="#fff" stroke-linecap="round"
                                                      stroke-width="1"/>
                                            </g>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </div>

                        {/*}*/}
                    </Col>
                </Row>
            </Container>
            <svg className={'logo-svg'} data-speed={"clamp(0.8)"} width="735" height="388" viewBox="0 0 735 388" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.03" fill-rule="evenodd" clip-rule="evenodd"
                      d="M165.316 116.727C210.565 94.6929 260.739 74.0288 314.091 55.9664C522.508 -14.2238 703.33 -17.0867 731.139 45.4853C749.513 86.8703 697.623 149.238 601.236 209.694C662.992 161.283 692.984 114.546 678.228 81.3123C650.451 18.8728 472.551 21.1473 267.882 89.9344C215.331 107.615 165.721 127.969 120.948 149.861C80.8665 169.5 44.5977 190.131 13.6579 211.159C51.5861 179.38 103.737 146.763 165.435 116.692L165.316 116.727ZM159.849 155.574C201.901 137.761 250.005 120.698 301.732 105.961C478.262 55.5195 633.026 49.927 653.3 92.3074C667.187 121.397 615.069 166.362 524.435 209.335C582.238 174.745 612.939 141.573 602.368 118.311C582.49 74.3923 421.819 83.0268 241.996 137.293C195.581 151.295 152.392 167.033 113.817 183.381C90.3103 193.383 68.5428 203.718 48.9273 213.979C77.2001 194.599 115.121 174.469 159.936 155.406L159.849 155.574ZM476.078 261.982C455.668 271.059 434.026 279.795 411.511 288.08C219.326 357.827 34.7304 358.266 -3.69086 291.903C-12.2546 276.99 -12.8072 259.826 -6.38031 241.431C-39.0789 279.034 -51.3138 315.214 -35.9507 343.011C0.150774 408.22 173.155 400.448 346.033 329.507C363.451 322.317 380.265 314.739 396.388 306.942C425.396 292.74 452.147 277.65 476.078 261.982Z"
                      fill="#F6F8FE"/>
            </svg>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background: #282C32;
    position: relative;
    .logo-svg{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99999;
    }
    .container {
        position: relative;
    }
    .title{
        span{
            display: block;
            padding-left: 100px;
            @media(max-width: 767px){
                padding-left: 0;
            }
        }
    }

    .amenities__big {
        position: relative;

        .title {
            display: none;

            h2 {
                color: #FFF;
            }

            @media (max-width: 991px) {
                display: block;
            }
        }

        &__slider {
            position: relative;
            width: 80%;

            .shadow {
                position: absolute;
                bottom: -50px;
                right: calc(100% - 43px);
                z-index: 2;
                width: 120px;

                img {
                    width: 100%;
                    object-fit: cover;
                    border-radius: 10px;
                }
            }

            .shadow-right {
                position: absolute;
                top: -30px;
                z-index: 9;
                right: -49px;
            }

            .single {
                padding-top: calc(550 / 400 * 100%);
                border-radius: 10px;
                position: relative;
                transform: translateY(80px);
                opacity: 0;
                transition: all 1s ease;
                transition-delay: 0s;

                img {
                    border-radius: 10px;
                }

                @media (max-width: 991px) {
                    padding-top: calc(320 / 300 * 100%);
                }
            }
        }

        &__thumb {
            width: 30%;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 15px;
            height: fit-content;
            z-index: 3;

            &__inner {
                .single {
                    padding-top: 100%;
                    background-color: #EEE;
                }
            }
        }
    }

    .amenities__right {
        //padding-top: 50px;
        position: relative;
        .title{
            @media(max-width: 991px){
                display: none;
            }
        }
        &__bottom{
            position: relative;
        }
        &__text{
            width: 100%;
            @media(max-width: 991px){
                width: 100%;
                margin-top: 40px;
            }
        }

        h2 {
            color: #F6F8FE;
        }

        h5 {
            color: #F6F8FE;
            font-family: ${title};
        }
        .content-wrapper{
            position: relative;
            transform: translateY(80px);
            opacity: 0;
            transition: all 1s ease;
            transition-delay: 0s;
        }

        .review {
            margin-bottom: 60px;
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }

        p {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            white-space: normal;
            color: #F6F8FE;

            @media (max-width: 1024px) {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
            }
        }

    }
    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0 !important;
        transition: all 0.1s ease;
    }
    .swiper-slide-active{
        .content-wrapper{
            opacity: 1;
            transform: none;
        }
        .single{
            opacity: 1;
            transform: none;
        }
    }

    .solutions-nav {
            position: absolute;
        bottom: 20px;
        right: 0;
        z-index: 999999;
        ul {
            display: flex;

            li {
                cursor: pointer;

                svg g {
                    transition: all .8s ${Transition};
                }

                #Rectangle_493 {
                    opacity: 1;
                    stroke-dasharray: 0, 520;
                }

                &:nth-child(1) {
                    margin-right: 20px;
                }

                &:hover {
                    svg #Rectangle_493 {
                        stroke-dasharray: 125;
                        stroke-width: 1;
                    }
                }
            }
        }
        @media (max-width: 991px) {
            position: relative;
            z-index: 99999999;
            margin-top: 40px;
        }
    }

    @media (max-width: 991px) {
        .col-sm-5, .col-sm-4 {
            min-width: 100%;
            //padding: 0 30px;
        }

        .amenities__big__thumb {
            width: 45%;
            top: auto;
            bottom: -200px;
        }

        .amenities__right {

            p {
                display: block;
                overflow: visible;
            }
        }

        .amenities__big__slider .shadow {
            z-index: 1;
            width: 120px;
            right: 0;
            left: calc(23% + 180px);
        }

        .slider-nav {
            bottom: 0 !important;
            width: 98% !important;
        }

    }

    @media (max-width: 767px) {
        padding-top: 60px;
        .amenities__big__thumb {
            height: 150px;
            width: 55%;
            bottom: -75px;
            left: 30px;

            div {
                height: 100%;
                width: 100%;
            }

            .single {
                padding: 0 !important;
            }
        }

        .amenities__big__slider {
            .shadow-right {
                right: 0;
            }
        }
        
    }

    .swiper-pagination {
        opacity: 0;
        visibility: hidden;
    }

    .amenities__small__slider {
        margin-top: calc(-25%);
        margin-left: 50%;
        position: relative;
        z-index: 99999;

        .single {
            position: relative;
            padding-top: calc(100%);
            transform: translateY(80px);
            opacity: 0;
            transition: all 1s ease;
            transition-delay: 0s;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
        @media(max-width: 991px){
            margin-top: calc(-40%);
        }
    }

    .nav-button {
        position: relative;

        .solutions-nav {
            position: absolute;
            bottom: calc(25%);
            right: 0;
        }
    }
    .swiper{
        border-radius: 10px;
    }
    @media(max-width: 767px){
        overflow: hidden;
    }

    .swiper-slide:not(.swiper-slide-active) {
        opacity: 0 !important;
        transition: all 0.1s ease;
    }
    .swiper-slide-active{
        .content-wrapper{
            opacity: 1;
            transform: none;
        }
        .single{
            opacity: 1;
            transform: none;
        }
    }
`;

export default MyComponent;
