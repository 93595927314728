'use client'
import {Img} from "@/components/Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import React, {useEffect} from "react";
import styled from "styled-components";
import Title from "@/components/Title";
import Button from "@/components/Button";


const Overview = ({imgOne,imgTwo, text, title}) => {

    return (
        <StyledOverview className='home-cta '>
            <Container fluid>
                <Row className={'top'}>
                    <Col md={3}>
                        <div className="top-img">
                            <div className="top-img__wrapper">
                                <img data-speed={"clamp(1.1)"} src={imgOne} alt={''}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col md={{span: 8, offset: 2}} className={'home-cta-content'}>
                        <Title text={title}
                               margin={'50px 0 40px 0'} animClass={'split-left'}/>
                        <Button src={'/contact'} text={'Contact with Us'}
                                borderBottom={'#181D24'}
                                hoverborder={'#181D24'}
                                hoverColor={'#181D24'}
                                margin={'0 0 30px 0'}
                        />
                    </Col>
                </Row>
            </Container>
            <Container fluid className={'bottom'}>
                <Row>
                    <Col md={{span: 3, offset: 9}}>
                        <div className={'bottom-img'}>
                            <div className="bottom-img__wrapper">
                                <img data-speed={"clamp(1.1)"} src={imgTwo} alt={''}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <svg width="575" height="332" viewBox="0 0 575 332" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.05" fill-rule="evenodd" clip-rule="evenodd"
                      d="M52.7011 7.65727C101.774 13.8012 154.497 23.8837 208.805 37.9984C420.79 93.4565 578.671 191.931 574.681 261.755C572.026 307.926 499.107 333.073 388.509 331.718C463.711 324.19 510.653 300.379 512.785 263.302C516.743 193.611 361.657 96.2573 153.547 41.6739C100.104 27.6758 48.0084 17.6387 -0.588091 11.6373C-44.1106 6.30023 -84.7764 3.95267 -121.005 4.9251C-73.9007 -1.47472 -14.1031 -0.661028 52.8202 7.6929L52.7011 7.65727ZM30.5378 38.3143C74.9478 46.3364 124.263 58.3878 175.671 74.4812C351.182 129.273 487.721 210.83 486.264 258.925C485.239 291.921 419.93 301.84 322.158 288.526C387.736 290.784 429.206 279.141 430.488 253.053C432.978 203.844 289.96 121.629 109.881 68.3167C63.403 54.5523 18.9393 44.0946 -21.8023 36.7432C-46.6474 32.298 -70.1355 29.1128 -91.7265 27.0645C-58.5469 26.0335 -16.675 29.7382 30.6888 38.2171L30.5378 38.3143ZM256.661 307.212C234.913 303.693 212.251 299.19 189.035 293.809C-8.6936 247.028 -168.755 144.343 -172.255 65.305C-172.981 47.5826 -165.759 32.3805 -151.94 20.0062C-197.128 34.3799 -223.956 58.9439 -223.123 91.6422C-221.114 168.384 -67.8012 258.234 113.744 293.198C132.054 296.684 150.015 299.497 167.476 301.733C198.969 305.605 228.906 307.447 256.661 307.212Z"
                      fill="#282C32"/>
            </svg>

        </StyledOverview>
    );
};

const StyledOverview = styled.section`
    position: relative;
    svg{
        position: absolute;
        bottom: 60px;
        left: 0;
    }
    .container-fluid {
        padding: 0;
    }

    .top {
        margin-right: 0;
    }

    .top-img {
        margin-top: calc(-30%);

        &__wrapper {
            position: relative;
            padding-top: calc(400 / 368 * 100%);
            border-radius: 10px;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }

        }

        @media (max-width: 767px) {
            margin-top: calc(-20%);
            width: 60%;
            &__wrapper {
                padding-top: calc(218 / 200 * 100%);

            }
        }
    }
    .home-cta-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        h2{
            text-align: center;
        }
    }

    .bottom-img {
        &__wrapper {
            position: relative;
            padding-top: calc(250 / 370 * 100%);
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        @media (max-width: 767px) {
            width: 60%;
            &__wrapper {
                padding-top: calc(135 / 200 * 100%);
            }
        }
    }

    @media (max-width: 767px) {
        .bottom {
            .col-md-3 {
                display: flex;
                justify-content: flex-end;
                padding-right: 0;
            }
            .row{
                margin-right: 0;
            }
        }
        .title{
            margin-top: 60px;
        }
        .dc-btn{
            margin-bottom: 90px;
        }
        .home-cta-content{
            display: flex;
            flex-direction: column;
            align-items: center;
            h2{
                text-align: center;
            }
        }
        svg{
            width: 60%;
        }
    }
    
`;


export default Overview;
