'use client';
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import HomeTown from "@/components/home/HomeTown";
import HomeTownTwo from "@/components/home/HomeTownTwo";


const Overview = ({ townOne,townTwo }) => {


    return (
        <StyledOverview className='pt-150 pb-150'>
            <HomeTown data={townOne}/>
            <HomeTownTwo data={townTwo}/>
        </StyledOverview>
    );
};

const StyledOverview = styled.section`
    background-color: #282C32;
`;

export default Overview;
